.experience {
    height: 100vh;
    padding: 50px 20px;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
}

.container {
    width: 90%;
    text-align: center;
}

.table-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px;
  overflow-x: auto; /* Permite scroll horizontal si el contenido es más ancho */
}

.experience-table {
  border-collapse: collapse;
  width: 100%;
  max-width: 1100px;
  margin-bottom: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  animation: fadeIn 1s ease-in-out;
  min-width: 600px; /* Asegura un tamaño mínimo para pantallas pequeñas */
}

  .experience-table th h1{
    font-family: "Karla", sans-serif;
    font-optical-sizing: auto;
    color:#FFF;
    font-size: 50px;
  }
  
  .experience-table td {
    border-bottom: 1px solid #ddd;
    padding: 30px 20px 30px 20px;
    text-align: start;
    color: #FFFFFF;
    font-size: 17px;
    font-weight: 600;
    vertical-align: top;
  }

  .colorGray {
    color:#8b8b8b !important;
  }
  
  .experience-table th {
    background-color: transparent;
    color: #FFFFFF;
  }
  
  .table-row:hover {
    background-color: #3b3a3a9c;
    transition: background-color 0.3s;
  }
  
  .pagination {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
  }
  
  .pagination button {
    padding: 8px 16px;
    border: none;
    background-color: #007bff;
    color: #fff;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
  }
  
  .pagination button:hover {
    background-color: #0056b3;
  }
  
  .pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @media (max-width: 868px) {
    .experience {
      display: block;
      height: auto;
    }

    .experience-table {
        display: block; 
        min-width: 100%; 
    }

    .experience-table tbody tr {
        display: flex;
        flex-direction: column;
        margin-bottom: 10px;
        border: 1px solid #ddd;
        border-radius: 8px;
        background-color: #2a2a2a;
    }

    .experience-table tbody tr td {
        display: flex;
        justify-content: flex-start;
        padding: 15px;
        border-bottom: none;
    }

    .experience-table tbody tr td::before {
        content: attr(data-label); /* Usa el atributo data-label para mostrar el encabezado */
        font-weight: bold;
        color: #ccc;
        margin-right: 10px;
    }
}