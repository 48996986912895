/* Encabezado con menú
    .home-header {
        position: fixed;
        top: 0;
        width: 100%;
        background: rgba(0, 0, 0, 0.151);
        z-index: 10;
        padding: 20px 0;
      }
      
      .home-header.hidden {
        transform: translateY(-100%) !important;
        transition: transform 0.3s ease-in-out;
      }
    
      .home-nav {
        text-align: center;
      }
      
      .menu {
        list-style: none;
        padding: 0;
        margin: 0;
        display: inline-flex;
        gap: 50px;
      }
      
      .menu li {
        display: inline;
      }
      
      .menu a {
        text-decoration: none;
        color: rgb(0, 0, 0);
        font-weight: bold;
        font-family: 'Trench Thin', serif;
        font-optical-sizing: auto;
      }
      
      .menu a:hover {
        color: #675B33;
      }
  
  .home-header.hidden {
    transform: translateY(-100%);
  } */

/* Botón para alternar el header */
.toggle-button {
  position: fixed;
  top: 20px;
  left: 20px;
  z-index: 1000;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  transition: transform 0.3s ease-in-out;
}

.toggle-button:hover {
  transform: rotate(90deg);
}

/* Header vertical */
.vertical-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100vh;
  background: linear-gradient(45deg, #ebca38, #f08f21);
  box-shadow: 5px 0 15px rgba(0, 0, 0, 0.3);
  transform: translateX(-100%);
  transition: transform 0.4s ease-in-out, opacity 0.4s ease-in-out;
  opacity: 0;
  z-index: 999;
}

.vertical-header .language-toggle {
  text-align: center;
  margin-top: 30px;
  margin-right: 30px;
  float: right;
}

/* .vertical-header .language-toggle button {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
} */

.language-btn {
  background-color: transparent;
  border: none;
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.language-btn.selected {
  border-radius: 5px;
  font-weight: 700;
  transform: scale(1.1);
}

.language-btn:hover {
  background-color: #3e3e3e;
  transform: scale(1.05);
}

.vertical-header.show {
  transform: translateX(0);
  opacity: 1;
}

.vertical-header.hide {
  transform: translateX(-100%);
  opacity: 0;
}

.vertical-header nav {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  padding-left: 60px;
}

.menu {
  list-style: none;
  padding: 0;
}

.menu li {
  margin: 35px 0;
}

.menu a {
  font-family: "Fira Code", monospace;
  text-decoration: none;
  font-size: 25px;
  font-weight: bold;
  color: #f1f1f1;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.menu a:hover {
  transform: scale(1.3);
  color: #ff6347;
}

.social-header-links {
  width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 20px;
  gap: 45px;
  margin: 20px 0;
  z-index: 9999;
}

.social-header-icon {
  width: 45px;
  height: 45px;
  text-decoration: none;
  font-size: 45px;
  color: #fff;
  transition: transform 0.3s ease, color 0.3s ease;
}

.social-header-icon:hover {
  transform: scale(1.1);
  color: #ff6347;
}


.menu-container {
  position: absolute;
  bottom: 70px;
  margin: 0 auto;
  width: 100%;
  display: flex;
  align-items: center;
  z-index: 99;
  background-color: red;
}

.bottom-menu {
  position: fixed;
  bottom: 0;
  width: 100%;
  background: #222;
  color: white;
  text-align: center;
  padding: 10px 0;
  font-size: 22px;
  background-color: #00000096;
  margin-bottom: 20px;
}

.menu-item {
  font-family: "Fira Code", monospace;
  padding: 0 10px;
  cursor: pointer;
}

.menu-item:hover {
  color: #1db954;
}

.menu-icon {
  position: fixed;
  left: 10%;
  width: 60px;
  cursor: pointer;
}

.menu-item {
  cursor: pointer;
  margin: 0 5px;
  font-weight: bold;
  transition: color 0.3s ease;
}

.menu-item:hover {
  color: #007bff;
}