.about {
    position: relative;
    height: 100vh;
    padding: 5vh 5vw;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.profile-container {
    position: relative;
    display: flex;
    align-items: center;
    gap: 30px;
    max-width: 900px;
    margin: 0 auto;
}

.profile-image {
    flex-shrink: 0;
    width: 250px;
    height: 250px;
    background-image: url("../../assets/images/perfil.jpg");
    border-radius: 50%;
    overflow: hidden;
    background-size: cover;
    background-position: center;
}

.profile-description {
    flex-grow: 1;
}

.profile-description h2 {
    font-size: 2rem;
    margin-bottom: 15px;
}

.profile-description p {
    font-size: 1rem;
    line-height: 1.6;
}

/* Agrega algunos estilos de respuesta */
@media (max-width: 768px) {
    .profile {
        flex-direction: column;
        text-align: center;
    }

    .profile-image {
        margin-bottom: 20px;
    }
}

.profile-expirence {
    position: relative;
    display: grid;
    grid-template-columns: 30% 70%;
    gap: 20px;
    margin-top: 50px;
}

.years-expirence {
    width: 80%;
    text-align: center;
}

.years-expirence h1 {
  font-size: 90px;
}

.years-expirence span {
    font-size: 20px;
    color:#666;
}


.profile-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 50px;
}

.grid-item {
    width: 250px;
    background-color: #f8f8f8;
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}

.grid-item h3 {
    margin-bottom: 10px;
    color: #333;
}

.grid-item p {
    color: #666;
}


/* Responsive Adjustments */
@media (max-width: 768px) {

    .about {
        display: block;
    }

    .profile {
        position: relative;
        top: 30px;
    }
    .profile-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        align-items: center;
        gap: 2rem;
        width: 100%;
        max-width: 1200px;
        text-align: center;
        flex-direction: column;
    }

    .profile-expirence {
        grid-template-columns: 1fr;
        margin-top: 15px !important;
    }

    .profile-description {
        max-width: 90%;
    }

    .years-expirence {
        width: 100%;
    }

    .grid-item {
        width: auto;
    }
    .profile-grid {
        margin-bottom: 50px;
    }
}

@media (max-width: 480px) {
    .profile-container {
        gap: 1rem;
    }

    .profile-grid {
        grid-template-columns: 1fr;
    }
}